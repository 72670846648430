import neighborhoodsMap from '@/constants/locations/neighborhoods.json'
import type { InvestmentProjectSummary } from '@/server/api/buyer-front/types'
import type { Filter } from '@/composables/useOnboarding/types'
import type { SearchResultsBuilderContext } from '@/composables/useOnboarding/builders/SearchResultsBuilder/types'
import { sortByLocation } from '@/utils/locations'
import { typologyBedroomsFilter, typologyPriceFilter } from '@/composables/useOnboarding/filters'

export default class SearchResultsBuilder {
  private readonly ctx: SearchResultsBuilderContext

  constructor (ctx: SearchResultsBuilderContext) {
    this.ctx = ctx
  }

  public buildExactMatchResults () {
    const exactMatchResults = this.ctx.baseResults.filter((project) => {
      const matchesAllFilters = this.ctx.filters.every(filter => filter.filter(filter.value, project, false, this.ctx.locationsQueryUtils))
      return matchesAllFilters
    })

    const customTitle = this.ctx.customTitle
    const selectedNeighborhood = neighborhoodsMap[customTitle]

    const [outstandingProject, ...exactMatchResultsWithoutOutstanding] = exactMatchResults
      .map(result => this.removeNonMatchingTypologies(result, this.ctx.filters))
      .filter(project => project.typologies.length > 0)
      .sort(
        (a, b) => this.sortingResults(a, b, selectedNeighborhood)
      )

    return {
      outstandingProject,
      exactMatchProjects: exactMatchResultsWithoutOutstanding
    }
  }

  public buildExtendedSearchResults () {
    const extendedSearchCarrousels = []

    this.ctx.filters.forEach((currentFilter) => {
      const filteredResults = this.ctx.baseResults.filter((project) => {
        const matchesAllFiltersExceptCurrent = this.ctx.filters.every(filter => filter.filter(filter.value, project, filter.key === currentFilter.key, this.ctx.locationsQueryUtils))
        return matchesAllFiltersExceptCurrent
      })

      const customTitle = this.ctx.customTitle
      const selectedNeighborhood = neighborhoodsMap[customTitle]

      extendedSearchCarrousels.push({
        filter: currentFilter.key,
        projects: filteredResults
          .map(project => this.removeNonMatchingTypologies(project, this.ctx.filters, { bedrooms_number: currentFilter.key === 'bedrooms_number', price_range: currentFilter.key === 'price_range' }))
          .filter(project => project.typologies.length > 0)
          .sort((a, b) => this.sortingResults(a, b, selectedNeighborhood))
      })
    })

    return extendedSearchCarrousels
  }

  public buildExtraResults () {
    const selectedFilter = this.ctx.filters.find(filter => filter.key === 'neighborhoods')

    if (!selectedFilter) return []

    const filteredProjects = this.ctx.baseResults
      .filter(project => selectedFilter.filter(selectedFilter.value, project, false, this.ctx.locationsQueryUtils))
      .sort((a, b) => this.sortingResults(a, b, ''))

    return [{
      filter: selectedFilter.key,
      projects: filteredProjects
    }]
  }

  public sortingResults (a, b, selectedNeighborhood: string) {
    return sortByLocation(selectedNeighborhood, a.neighborhood_label, b.neighborhood_label)
  }

  public removeNonMatchingTypologies (project: InvestmentProjectSummary, filters: Filter[], opposite = { bedrooms_number: false, price_range: false }) {
    const selectedBedroomsNumber = filters.find(filter => filter.key === 'bedrooms_number')?.value || null
    const selectedPriceRange = filters.find(filter => filter.key === 'price_range')?.value || { min: null, max: null }

    return {
      ...project,
      typologies: project.typologies.filter((typology) => {
        let matchesToBedrooms = typologyBedroomsFilter(selectedBedroomsNumber as (number | null), typology)
        let matchesToPriceRange = typologyPriceFilter(selectedPriceRange as ({ min: number, max: number }), typology)

        if (opposite.bedrooms_number) matchesToBedrooms = !matchesToBedrooms
        if (opposite.price_range) matchesToPriceRange = !matchesToPriceRange

        return matchesToBedrooms && matchesToPriceRange
      })
    }
  }
}
