import type { Filter, SearchResults } from '@/composables/useOnboarding/types'
import type { SobreplanosDataServiceMethods } from '@/services/sobreplanosDataService/types'
import type { InvestmentProjectSummary } from '@/server/api/buyer-front/types'
import { convertFiltersArrayToObject } from '@/composables/useOnboarding/utils'
import SearchResultsBuilder from '@/composables/useOnboarding/builders/SearchResultsBuilder/v1'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { LocationsQueryUtils } from '@/stores/locationsStore/types'

export default class SearchService {
  private readonly $sobreplanosDataService: SobreplanosDataServiceMethods
  private readonly locationsQueryUtils: LocationsQueryUtils
  private readonly customTitle: string | undefined

  constructor (config: {
    $sobreplanosDataService: SobreplanosDataServiceMethods,
    locationsQueryUtils: LocationsQueryUtils,
    customTitle?: string
  }) {
    this.$sobreplanosDataService = config.$sobreplanosDataService
    this.locationsQueryUtils = config.locationsQueryUtils
    this.customTitle = config.customTitle
  }

  async search (filters: Filter[]): Promise<SearchResults> {
    const serverFilters = filters.filter(filter => filter.type === 'backend')
    const response = await this.searchByServer(serverFilters)
    const baseResults = response.results

    const frontEndFilters = filters.filter(filter => filter.type === 'frontend')

    const searchResultsBuilder = new SearchResultsBuilder({
      baseResults,
      filters: frontEndFilters,
      customTitle: this.customTitle,
      locationsQueryUtils: this.locationsQueryUtils
    })

    const exactMatchResults = searchResultsBuilder.buildExactMatchResults()
    const extendedSearch = searchResultsBuilder.buildExtendedSearchResults()
    const extraResults = searchResultsBuilder.buildExtraResults()

    return {
      ...exactMatchResults,
      extendedSearch,
      extraResults
    }
  }

  async searchByServer (serverFilters: Filter []): Promise<{ results: InvestmentProjectSummary[] }> {
    // @ts-ignore
    const filters = convertFiltersArrayToObject(serverFilters)

    const { cacheRefresh } = useSearchFiltersStore()

    const pagination = {
      page: 1,
      limit: 1000
    }

    const results = await this.$sobreplanosDataService.getListings({ filters, pagination, refresh: cacheRefresh })
    return results
  }
}
